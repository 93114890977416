.Interests-Body {
    background-color: white;
}

.Interests-Body.dark {
    background: #24223C;
}

.Interests-container {
    display: flex;
    flex-direction: column;
    max-width: 750px;
    margin: 0 16px;
    background: white;
    color: #162146;
}

.Interests-container.dark {
    background: #24223C;
    color: white;
}

.Interests-Title {
    align-self: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 5px;
    line-height: 19px;
}

.Interests-Description {
    align-self: flex-start;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 15px;
}

.Interests-Grid {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
    border: #ECF1FB solid 1px;
}

.Interests-Grid.dark {
    border: #2F2C49 solid 1px;
}

.Interests-Grid-Item {
    display: flex;
    width: 30%;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    border: #ECF1FB solid 1px;
    height: 100px;
}

.Interests-Grid-Item.dark {
    border: #2F2C49 solid 1px;
}

.Interests-Grid-Item.enabled {
    background-color: #5A4FCF;
    color: white;
    border: #5A4FCF solid 1px;
}

.Interests-Grid-Item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin: 12px 0 0 0;
}

.Interests-Clear-Interests-Button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    height: 48px;
    margin: 15px 0;
    background: #F3F2FC;
    border-radius: 8px;
    cursor: pointer;
}

.Interests-Clear-Interests-Button.dark {
    background: #2C2946;
}

.Interests-Clear-Interests-Button:hover {
    transform: scale(0.99);
}

.Interests-Clear-Interests-Button:active {
    transform: scale(1.01);
}

.Interests-Clear-Interests-Button-Text {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #4C2A9F;
}

.Interests-Clear-Interests-Button-Text.dark {
    color: #796EF0;
}

.Interests-Save-Button {
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    height: 48px;
    background: #5A4FCF;
    border-radius: 8px;
    cursor: pointer;
}

.Interests-Save-Button.dark {
    background: #5A4FCF;
}

.Interests-Save-Button:hover {
    transform: scale(0.99);
}

.Interests-Save-Button:active {
    transform: scale(1.01);
}

.Interests-Save-Button-Text {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}
